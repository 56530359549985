import {
  Container,
  Grid,
  GridItem,
  Flex,
  Box,
  Text,
  Heading,
  Divider,
} from '@chakra-ui/react';

function AboutUs() {
  return (
    <Container py={5} maxW={'container.lg'}>
      <Box textAlign={"center"}>
        <GridItem w="100%" colSpan={{ base: 2, sm: 2, md: 2 }}>
          <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'} mb={5}>
              Tentang Kami
          </Heading>
          <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'lg' }} mt={5}>
            Alhanif Project menawarkan jasa pembuatan website, aplikasi kasir dan aplikasi untuk keperluan pendidikan serta mengembangkan aplikasi yang sudah client kami punya sebelumnya.
          </Text>
          <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'lg' }} mt={5}>
            Kami berdedikasi untuk menciptakan ide kreatif dan bersenang-senang di lingkungan kerja profesional yang santai.
          </Text>
          <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'lg' }} mt={5}>
            Staff kami adalah profesional mapan yang bersemangat dengan pekerjaan mereka sendiri sebagai programmer dan membawa antusiasme itu ke project yang sedang di kerjakan.
          </Text>
          <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'lg' }} mt={5}>
            Apa pun bisnis Anda dan berapa pun usia atau tingkat keahlian Anda,
            kami memiliki keperayaan bisnis Anda akan berkembang jika menggunakan dan masuk ke digital.
          </Text>
        </GridItem>
      </Box>

      <Box ml={2} mr={2} mt={30}></Box>

      <Divider />

    </Container>
  );
}

export default AboutUs;