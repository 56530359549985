import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Stack,
    Text,
    Link
  } from '@chakra-ui/react';
  import { ReactElement } from 'react';
  // import {
  //   FcAbout,
  //   FcAssistant,
  //   FcCollaboration,
  //   FcDonate,
  //   FcManager,
  // } from 'react-icons/fc';
  import { LazyLoadImage } from "react-lazy-load-image-component";
  import Images from '../../../src/assets/index';
  
  interface CardProps {
    heading: string;
    description: string;
    icon: ReactElement;
    link: string;
    is_image?: boolean;
  }
  
  const Card = ({ heading, description, icon, link, is_image }: CardProps) => {
    return (
      <Box
        maxW={{ base: 'full', md: '275px' }}
        w={'full'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={5}>
        <Stack align={'start'} spacing={2}>
          <Flex
            align={'center'}
            justify={'center'}
            color={'white'}
            // rounded={'full'}
            // bg={useColorModeValue('gray.100', 'gray.700')}
            >
            {icon}
          </Flex>
          <Box mt={2}>
            <Heading size="md">{heading}</Heading>
            <Text mt={1} fontSize={'sm'}>
              {description}
            </Text>
          </Box>
          <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
          <Link color='teal.500' href={link}>
            Lihat Detail
          </Link>
          </Button>
        </Stack>
      </Box>
    );
  };
  
  export default function Portofolio() {
    return (
      <Box p={4}>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
          Portofolio
          </Heading>
          <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
          "Mengubah Ide Menjadi Kreasi Indah: Keluarkan Kekuatan Desain dengan Portofolio Kami"
          </Text>
        </Stack>
  
        <Container maxW={'7xl'} mt={12}>
          <Flex flexWrap="wrap" gridGap={6} justify="center">
          <Card
              heading={'SIT ALFIKRI MAKASSAR'}
              icon={
                <LazyLoadImage src={Images.porto_sit_alfikri}
                width={600} height={400}
                alt="Image Alt"
              />
              }
              description={
                'SIT ALFIKRI Makasar merupakan Sekolah yang dibentuk atas dasar nilai-nilai Al Qur’an dan Akhlak Islami,...'
              }
              link={'https://sitalfikrimakasar.sch.id/'}
            />
            <Card
              heading={'CV ALBAROKAH HERBAL'}
              icon={
                <LazyLoadImage src={Images.porto_cv_albarokah_herbal}
                width={600} height={400}
                alt="Image Alt"
              />
              }
              description={
                'Perusahaan yang bergerak di industri obat tradisional hususnya saat ini memproduksi obat herbal Cairan Obat Luar perusahaan ini didirikan tahun 2019 oleh Bapak Wawan Rusmady S,S.Pd.,M.Pd...'
              }
              link={'https://albarokahherbalmakassar.co.id/'}
            />
            <Card
              heading={'ALQUDS VOLUNTEER INDONESIA'}
              icon={
                <LazyLoadImage src={Images.porto_alquds_volunteer_indonesia}
                width={600} height={400}
                alt="Image Alt"
              />
              }
              description={
                'AVI adalah singkatan dari Alquds Volunteer Indonesia sebagai lembaga kemanusiaan berbentuk Yayasan yang didasari oleh sifat kerelawanan,...'
              }
              link={'https://avihumanity.or.id/'}
            />
            <Card
              heading={'PPDB SIT ALFIKRI MAKASSAR'}
              icon={
                <LazyLoadImage src={Images.porto_ppdb_sit_alfikri_makasar}
                width={600} height={400}
                alt="Image Alt"
              />
              }
              description={
                'SIT AL FIKRI MAKASSAR menyediakan PPDB secara online diharapkan proses PPDB dapat berjalan cepat dan bisa dilakukan dimanapun dan kapanpun selama sesi PPDB Online dibuka.'
              }
              link={'https://ppdbonline.sitalfikrimakasar.sch.id/'}
            />
            <Card
              heading={'SIAKAD SIT AL FIKRI MAKASSAR'}
              icon={
                <LazyLoadImage src={Images.porto_siakad_sit_alfikri_makassar}
                width={600} height={400}
                alt="Image Alt"
              />
              }
              description={
                'Merupakan Pengeloaan Sistem Akademik SMP IT AL FIKRI MAKASSAR'
              }
              link={'https://siakad.sitalfikrimakasar.sch.id/'}
            />
            <Card
              heading={'APLIKASI HARIAN GURU'}
              icon={
                <LazyLoadImage src={Images.porto_aplikasi_harian_guru}
                width={600} height={400}
                alt="Image Alt"
              />
              }
              description={
                'Merupakan Aplikasi untuk pengeloaan kegiatan harian guru mulai dari mengajar, atur file pembelajaran, media...'
              }
              link={'#'}
            />
            <Card
              heading={'APLIKASI PENJUALAN PART MESIN JAHIT'}
              icon={
                <LazyLoadImage src={Images.porto_penjuaan_part_mesin_jahit}
                width={600} height={400}
                alt="Image Alt"
              />
              }
              description={
                'Merupakan Aplikasi untuk pengeloaan penjualan part mesin jahit di CV Maju Jaya...'
              }
              link={'#'}
            />
            <Card
              heading={'E-Learning Mobile'}
              icon={
                <LazyLoadImage src={Images.porto_e_learning}
                width={600} height={400}
                alt="Image Alt"
              />
              }
              description={
                'E-Learning Mobile adalah aplikasi yang bisa mengakeses pembelajaran diseolah secara online melalui sebuah aplikasi'
              }
              link={'#'}
            />
            <Card
              heading={'AVI MOBILE'}
              icon={
                <LazyLoadImage src={Images.porto_avi_mobile}
                width={600} height={400}
                alt="Image Alt"
              />
              }
              description={
                'Avi Mobile adalah akses profile alquds volunteer indonesia melalui aplikasi mobile, tersedia data relawab serta agenda agenda kegiatan.'
              }
              link={'https://play.google.com/store/apps/details?id=com.alqudsvolunteerindonesia'}
            />
            <Card
              heading={'Inventory App'}
              icon={
                <LazyLoadImage src={Images.porto_inventory_app}
                width={600} height={400}
                alt="Image Alt"
              />
              }
              description={
                'Inventory App adalah Aplikasi mobile untuk pengeloaan keluar masuk nya stok barang.'
              }
              link={'#'}
            />
          </Flex>
        </Container>
      </Box>
    );
  }