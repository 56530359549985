const Images = {
    appLogo: require('./alhaniflogo.png'),
    porto_sit_alfikri: require('./sit_al_fikri.png'),
    porto_cv_albarokah_herbal: require('./cv_albarokah_herbal.png'),
    porto_alquds_volunteer_indonesia : require('./alquds_volunteer_indonesia.png'),
    porto_ppdb_sit_alfikri_makasar : require('./ppdb_sit_alfikri_makasar.png'),
    porto_siakad_sit_alfikri_makassar : require('./siakad_sit_alfikri_makassar.png'),
    porto_e_learning : require('./e_learning.jpg'),
    porto_avi_mobile : require('./alquds_app_dashboard.jpg'),
    porto_inventory_app : require('./inventory_app.jpg'),
    porto_aplikasi_harian_guru : require('./aplikasi_harian_guru.png'),
    porto_penjuaan_part_mesin_jahit : require('./penjuaan_part_mesin_jahit.png'),
    background:{
      hero1: require('./professional-programmer.jpg'),
      hero2: require('./computing-data-room.jpg'),
      hero3: require('./startup-company-boardroom.jpg'),
    },
  }
  
  export default Images;